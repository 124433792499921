import axios from 'axios';

// Use Axios to call a GET request to the spotify /me/playlists API, paginated using
// the limit and offset arguments.
export function GetPlaylists(access_token, limit, offset, callback) {
    console.log("request playlists using limit ", limit, " and offset ", offset)
    axios.get('https://api.spotify.com/v1/me/playlists',
        {
            headers:
            {
                'Authorization': 'Bearer ' + access_token
            },
            params: {
                limit: limit,
                offset: offset
            }
        })
        .then(function (response) {
            console.log(response);
            callback(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function SkipSong(access_token) {
    console.log("skip song request")
    axios.post('https://api.spotify.com/v1/me/player/next', {},
        {
            headers:
            {
                'Authorization': 'Bearer ' + access_token
            }
        })
        .then(function (response) {
            console.log("song skipped")
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function Pause(access_token) {
    console.log("playback pause request")
    axios.put('https://api.spotify.com/v1/me/player/pause', {},
        {
            headers:
            {
                'Authorization': 'Bearer ' + access_token
            }
        })
        .then(function (response) {
            console.log("playback paused")
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function Play(access_token, playlist_uri) {
    console.log("playback start request with playlist uri ", playlist_uri)
    playHelper(access_token, playlist_uri);
}

export function Resume(access_token) {
    console.log("playback resume request")
    playHelper(access_token, null);
}

function playHelper(access_token, playlist_uri) {
    axios.put('https://api.spotify.com/v1/me/player/play', {
        context_uri: playlist_uri
    },
        {
            headers:
            {
                'Authorization': 'Bearer ' + access_token
            }
        })
        .then(function (response) {
            console.log("playback started")
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function Transfer(access_token, device_id, player, callback) {
    console.log("playback transfer request")
    axios.put('https://api.spotify.com/v1/me/player',
        {
            device_ids: [device_id],
            play: true
        },
        {
            headers:
            {
                'Authorization': 'Bearer ' + access_token
            }
        })
        .then(function (response) {
            console.log("playback transferred")
            callback();
            // const max_retry = 10;
            // const retry_window = 1000;
            // let current_retry = 0;
            // let complete = false;

            // function pauseIfPlaying() {
            //     player.getCurrentState().then(state => {
            //         console.log("polling for playback state, result: ", state)
            //         if (state) {
            //             if (!state.paused) {
            //                 complete = true;
            //                 Pause(access_token);
            //             }
            //         }

            //         current_retry += 1;
            //         if (!complete && current_retry <= max_retry) {
            //             setTimeout(() => {
            //                 pauseIfPlaying();
            //             }, retry_window);
            //         }

            //     });
            // }

            // setTimeout(() => {
            //     pauseIfPlaying();
            // }, retry_window);

        })
        .catch(function (error) {
            console.log(error);
        });
}