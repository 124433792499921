import '../App.css';

import { useState } from 'react';
import { useEffect } from 'react';

import { GetPlaylists } from './SpotifyAPICalls.js';

export default function PlaylistComponent({ access_token, setPlaylist }) {

    const [playlists, setPlaylists] = useState([]);
    const [filteredPlaylists, setFilteredPlaylists] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        GetAllPlaylists(access_token, setPlaylists, setFilteredPlaylists);
    }, []);

    function handleSearchChange(event) {
        setSearchQuery(event.target.value);
        setFilteredPlaylists(playlists.filter(playlist => playlist.name.toLowerCase().includes(event.target.value.toLowerCase())));
    }

    function clearSearch() {
        setSearchQuery('');
        setFilteredPlaylists(playlists);
    }

    function renderPlaylists(playlists) {

        console.log("render playlists with ", playlists);
        return playlists.map(playlist => {

            const buttonHandler = () => {
                console.log("playlist button clicked")
                setPlaylist(playlist.uri);
                console.log("playlist glob set to ", playlist.uri);
            }

            return (
                    <div class="container-fluid fontFamilyRegular smallFontSize" key={playlist.uri} >
                        <div class="row">
                            <div class="col-1"/>
                            <button class="col-10 text-left playlistCell" onClick={buttonHandler} >
                                {playlist.name}
                             </button>
                             <div class="col-1"/>
                        </div>
                     </div>
                );

        });
    }

    return (
        <div class="playlistComponentDiv">
            <div class="container text-center my-5 fontFamilyBold fontColorDark logoFontSize">
                What Do You Want To Listen To?
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-1"/>
                    <input
                        class="searchBar col"
                        type="text"
                        placeholder="Search playlists..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    {/* <button class="clearButton col-1" onClick={clearSearch}>Reset</button> */}
                    <div class="col-1"/>
                </div>
            </div>
            <div class="container-fluid" style={{ "height": "40px"}}/>
            {renderPlaylists(filteredPlaylists)}
        </div>
    );
}

// Use GetPlaylists function to get all playlists for the user.  There will be an unknown 
// number of pages, so we will need to call the function multiple times.
function GetAllPlaylists(access_token, setPlaylists, setFilteredPlaylists) {
    console.log("get all playlists")
    let limit = 50;
    let offset = 0;
    let playlists = [];

    function callback(data) {
        data.items.forEach(item => {
            if (item != null && item.name != null && item.uri != null) {
                playlists.push(item)
            } else {
                console.log("playlist came back malformed")
            }
        });
        if (data.next) {
            offset += limit;
            GetPlaylists(access_token, limit, offset, callback);
        } else {
            setPlaylists(playlists);
            setFilteredPlaylists(playlists);
        }
    }

    GetPlaylists(access_token, limit, offset, callback);

}