import { useEffect } from "react";
import { useState } from "react";

import { Transfer } from './SpotifyAPICalls.js';

export default function PlayerComponent({ access_token, getPlayer, setPlayer, getDeviceId, setDeviceId }) {

    useEffect(() => {

        const script = document.createElement('script')
        script.src = 'https://sdk.scdn.co/spotify-player.js'
        script.type = 'text/javascript'
        document.head.appendChild(script)

        window.onSpotifyWebPlaybackSDKReady = () => {
            console.log(
                'The Web Playback SDK is ready. We have access to Spotify.Player'
            )

            /*global Spotify, a*/
            let playerLocal = new Spotify.Player({
                name: 'Web Playback SDK Quick Start Player',
                getOAuthToken: cb => {
                    cb(access_token)
                },
                volume: 0.5
            });

            playerLocal.isLoaded.then(() => {

                console.log("player loaded")

                // Ready
                playerLocal.addListener('ready', ({ device_id }) => {

                    console.log('Ready with Device ID', device_id);

                    setDeviceId(device_id);
                });

                // Not Ready
                playerLocal.addListener('not_ready', ({ device_id }) => {
                    console.log('Device ID has gone offline', device_id);
                });

                playerLocal.addListener('initialization_error', ({ message }) => {
                    console.error(message);
                });

                playerLocal.addListener('authentication_error', ({ message }) => {
                    console.error(message);
                });

                playerLocal.addListener('account_error', ({ message }) => {
                    console.error(message);
                });

                playerLocal.addListener('playback_error', ({ message }) => {
                    console.error(message);
                });

                playerLocal.addListener('autoplay_failed', ({ message }) => {
                    console.error(message);
                });

                playerLocal.connect();

                setPlayer(playerLocal);
            });
        }
    }, []);

    if (false) {
        return (
            <div>
                <ConnectButton access_token={access_token} player={getPlayer()} />
                <ActivateButton access_token={access_token} player={getPlayer()} />
                {/* <TransferButton access_token={access_token} device_id={device_id} player={getPlayer()} /> */}
            </div>
        )
    } else {
        return (
            <div />
        );
    }
};

function ActivateButton({ access_token, player }) {

    function handleClick() {
        console.log("activate button clicked")
        player.activateElement();
    }

    return (
        <button onClick={handleClick}>
            Activate
        </button>
    )
}

function ConnectButton({ access_token, player }) {
    function handleClick() {
        console.log("connect button clicked")
        player.connect();
    }

    return (
        <button onClick={handleClick}>
            Connect
        </button>
    )
}

function TransferButton({ access_token, device_id, player }) {

    function handleClick() {
        console.log("transfer button clicked")
        Transfer(access_token, device_id, player);
    }

    return (
        <button onClick={handleClick}>
            Transfer Playback
        </button>
    )
}