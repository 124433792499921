import '../App.css';
import '../index.css';

import { useState } from 'react';
import * as React from "react";
import { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import TimerComponent from './TimerComponent.js';
import PlaylistComponent from './PlaylistComponent.js';
import PlayerComponent from './PlayerComponent.js';
import LoginComponent from './LoginComponent.js';
import HeaderComponent from './HeaderComponent.js';

import { SkipSong, Play, Pause, Resume, Transfer } from './SpotifyAPICalls.js';

let player = null
let device_id = null

function setPlayer(newPlayer) {
  console.log("set player to ", newPlayer)
  player = newPlayer
}

function getPlayer() {
  console.log("get player returning", player)
  return player
}

function setDeviceId(newDeviceId) {
  console.log("set device id to ", newDeviceId)
  device_id = newDeviceId
}

function getDeviceId() {
  console.log("get device id returning", device_id)
  return device_id
}

function getAccessToken() {
  const token = window.localStorage.getItem("token");
  console.log("get access token returning", token)
  return token;
}

export default function UserComponent() {

  const [playlist, setPlaylist] = useState(null);
  
  console.log("acces token from local storage is ", window.localStorage.getItem("token"))

  console.log("load user home")

  function getPlaylist() {
    return playlist;
  }

  let skip = () => { }
  let stop = () => { }
  let resume = () => { }
  let start = () => { }

  if (getAccessToken() != null) {

    skip = () => {
      SkipSong(getAccessToken());
    }

    stop = () => {
      Pause(getAccessToken());
    }

    resume = () => {
      getPlayer().activateElement();
      Transfer(getAccessToken(), getDeviceId(), getPlayer(), () => {
        Resume(getAccessToken());
      });
    }

    start = () => {
      getPlayer().activateElement();
      Transfer(getAccessToken(), getDeviceId(), getPlayer(), () => {
        Play(getAccessToken(), getPlaylist());
      });
    }
  }

  const logout = () => {
    window.localStorage.removeItem("token");
    window.location.href = "/";
  }

  const clearPlaylist = () => {
    setPlaylist(null);
  }

  return (  
    <div>
      {getAccessToken() != null &&
        <HeaderComponent logoutLambda={logout}/>      
      }
      {getAccessToken() != null &&
        <PlayerComponent access_token={getAccessToken()} getPlayer={getPlayer} setPlayer={setPlayer} getDeviceId={getDeviceId} setDeviceId={setDeviceId} />
      }
      {getAccessToken() != null && getPlaylist() != null &&
        <TimerComponent skipLambda={skip} stopLambda={stop} pauseLambda={stop} resumeLambda={resume} startLambda={start} logoutLambda={logout} backLambda={clearPlaylist} />
      }
      {getAccessToken() != null && getPlaylist() == null &&
        <PlaylistComponent access_token={getAccessToken()} setPlaylist={setPlaylist} />
      }
      {getAccessToken() == null &&
        <LoginComponent />
      }
    </div>
  );
}