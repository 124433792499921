import '../App.css';
import '../index.css';

import { client_id } from '../Constants';

export default function LoginComponent() {

    async function handleClick() {

        const generateRandomString = (length) => {
            const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const values = crypto.getRandomValues(new Uint8Array(length));
            return values.reduce((acc, x) => acc + possible[x % possible.length], "");
        }

        const sha256 = async (plain) => {
            const encoder = new TextEncoder()
            const data = encoder.encode(plain)
            return window.crypto.subtle.digest('SHA-256', data)
        }   

        const base64encode = (input) => {
            return btoa(String.fromCharCode(...new Uint8Array(input)))
                .replace(/=/g, '')
                .replace(/\+/g, '-')
                .replace(/\//g, '_');
        }

        const codeVerifier = generateRandomString(64);
        const hashed = await sha256(codeVerifier)
        const codeChallenge = base64encode(hashed);

        window.localStorage.setItem('code_verifier', codeVerifier);
        window.localStorage.setItem('code_challenge', codeChallenge);

        let auth_redirect_uri = 'https://' + window.location.host + '/authorizeCallback';
        window.localStorage.setItem('auth_redirect_uri', auth_redirect_uri);

        console.log("auth request initiated")
        window.location.href =
            `https://accounts.spotify.com/authorize` +
            `?response_type=code&client_id=${client_id}` +
            `&redirect_uri=${auth_redirect_uri}` +
            `&code_challenge_method=S256` +
            `&code_challenge=${codeChallenge}` +
            `&scope=user-read-private%20user-read-email%20user-modify-playback-state%20streaming%20user-read-playback-state%20playlist-read-private%20playlist-read-collaborative` +
            `&show_dialog=true`;
    }

    return (
        <div>
            <div className="container-fluid d-flex flex-column justify-content-center align-items-center"  style={{"height":"33svh"}}>
                <div className="row align-items-center">
                    <div className="col text-center logoFontSize fontFamilyBold fontColorDark" style={{"font-size":"60px"}}>
                        SMOF
                    </div>
                </div>
            </div>
            <div className="container-fluid" style={{"height":"33svh"}}/>
            <div className="container-fluid d-flex flex-column justify-content-center align-items-center" style={{"height":"33svh"}}>
                <div className="row align-items-center">
                    <button type="button" className="col-auto buttonClass fontFamilyRegular smallFontSize" onClick={handleClick}>Login</button>
                </div>
            </div>
        </div>
    );
}