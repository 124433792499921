import '../App.css';
import '../index.css';

import { client_id } from '../Constants';

export default function HeaderComponent({ logoutLambda }) {
    return (
                    <div class="container-fluid" style={{ "background-color": "#002e60" }} >
                    <div class="row align-items-center">
                        <div class="col logoFontSize fontFamilyRegular" style={{"color":"white"}}>
                            SMOF
                        </div>
                        <button type="button" class="col-auto text-end me-2 buttonClass fontFamilyRegular smallFontSize" onClick={logoutLambda}>Logout</button>
                    </div>
            </div>
    );
}