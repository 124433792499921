import '../App.css';
import '../index.css';

import { client_id } from '../Constants';

import { Buffer } from 'buffer';
import { useSearchParams } from "react-router-dom";
import axios from 'axios';

export default function AuthorizeCallbackComponent() {

    // get code from search params
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code");

    console.log("load authorize callback")
    let code_verifier = window.localStorage.getItem('code_verifier');
    let auth_redirect_uri = window.localStorage.getItem('auth_redirect_uri');

    axios.post('https://accounts.spotify.com/api/token',
        {
            code: code,
            grant_type: 'authorization_code',
            redirect_uri: auth_redirect_uri,
            code_verifier: code_verifier,
            client_id: client_id,
        },
        {
            headers:
            {
                'Content-Type': 'application/x-www-form-urlencoded',
                // 'Authorization': 'Basic ' + Buffer.from(myString).toString('base64')
            }
        }
    )
        .then(function (response) {
            const access_token = response.data.access_token;
            console.log(access_token);
            axios.get('https://api.spotify.com/v1/me',
                {
                    headers:
                    {
                        'Authorization': 'Bearer ' + access_token
                    }
                })
                .then(function (response) {
                    console.log("logged in true")
                    window.localStorage.setItem("token", access_token)
                    window.location.href = "/";
                })
                .catch(function (error) {
                    console.log("logged in false")
                    return false;
                });

        })
        .catch(function (error) {
            console.log(error);
        });

    return (
        <div>
        <div className="container-fluid d-flex flex-column justify-content-center align-items-center"  style={{"height":"33svh"}}>
            <div className="row align-items-center">
                <div className="col text-center logoFontSize fontFamilyBold fontColorDark" style={{"font-size":"60px"}}>
                    SMOF
                </div>
            </div>
        </div>
        <div className="container-fluid" style={{"height":"33svh"}}/>
        <div className="container-fluid d-flex flex-column justify-content-center align-items-center" style={{"height":"33svh"}}>
            <div className="row align-items-center">
                <div className="col-auto buttonClass fontFamilyRegular smallFontSize fontColorDark">
                    Logging in...
                </div>
            </div>
        </div>
    </div>
    );
}