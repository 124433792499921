import '../App.css';
import '../index.css';

import { useState } from 'react';
import { useEffect } from 'react';

import { SkipSong, Play, Pause, Resume, Transfer } from './SpotifyAPICalls.js';

export default function TimerComponent({skipLambda, pauseLambda, resumeLambda, startLambda, stopLambda, logoutLambda, backLambda}) {
    const [totalDuration, setTotalDuration] = useState(3600);
    const [timeLeft, setTimeLeft] = useState(totalDuration);
    const [isRunning, setIsRunning] = useState(false);
    const [paused, setPaused] = useState(false);
    const [audio, setAudio] = useState(null);
    const [songLength, setSongLength] = useState(60);

    useEffect(() => {
        let timer;
        if (isRunning && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime % songLength == 0 && prevTime != totalDuration) {
                        audio.play();
                        skipLambda();
                    }
                    return prevTime - 1;
                });
            }, 1000);
        } else if (!isRunning && timeLeft !== 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [isRunning, timeLeft]);

    useEffect(() => {
        let audioLocal = new Audio();
        audioLocal.autoplay = true;
        audioLocal.src = "./beercan.mp3";
        setAudio(audioLocal);
    }, []);

    function stopTimer() {
        setIsRunning(false);
        setPaused(false);
        setTimeLeft(totalDuration);

        stopLambda();
    }

    function resumeTimer() {
        audio.play();
        setIsRunning(true);
        setPaused(false);

        resumeLambda();
    }

    function startTimer() {
        // Audio.play() in onClick function allows the browser to play audio without user interaction
        audio.play();
        setIsRunning(true);
        setPaused(false);

        startLambda();
    }

    function pauseTimer() {
        setIsRunning(false);
        setPaused(true);

        pauseLambda();
    }

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    function logout() {
        stopTimer();
        logoutLambda();
    }
    
    function back() {
        stopTimer();
        backLambda();
    }

    return (
        <div class="timerComponentDiv">
        <div class="container-fluid mt-2 ms-2">
                <div class="row">
                    {/* <div class="col logoFontSize fontFamilyRegular">
                        Back
                    </div> */}
                    <button type="button" class="col-auto text-end me-2 buttonClass fontFamilyRegular smallFontSize" onClick={back}>Back</button>
                </div>
        </div>
            <div class="timerTextDiv">
                <span class="bigFontSize fontFamilyRegular fontColorDark">{formatTime(timeLeft)}</span>
            </div>
            <TotalDurationComponent totalDuration={totalDuration} setTotalDuration={setTotalDuration} setTimeLeft={setTimeLeft} isRunning={isRunning} isPaused={paused} />
            <SongChangeComponent songLength={songLength} setSongLength={setSongLength} isRunning={isRunning} isPaused={paused}/>
            <div class="startButtonsDiv">
                <div class="container-fluid" style={{"height": "10px"}}/>
                <StartButton onClick={startTimer} isRunning={isRunning} isPaused={paused} />
                <div class="container-fluid" style={{"height": "10px"}}/>
                <StopButton onClick={stopTimer} isRunning={isRunning} />
                <div class="container-fluid" style={{"height": "10px"}}/>
                <PauseButton onClick={pauseTimer} isRunning={isRunning} isPaused={paused} />
                <div class="container-fluid" style={{"height": "10px"}}/>
                <ResumeButton onClick={resumeTimer} isPaused={paused} />
                <div class="container-fluid" style={{"height": "10px"}}/>
            </div>
        </div>
    );
}

function TotalDurationComponent({ totalDuration, setTotalDuration, setTimeLeft, isRunning, isPaused }) {

    const [selectedOption, setSelectedOption] = useState("1 hour");

    const optionsMap = new Map([
        ["30 minutes", 1800],
        ["1 hour", 3600],
        ["90 minutes", 5400],
        ["2 hours", 7200]
    ]);

    function handleChange(event) {
        setSelectedOption(event.target.value);
        let seconds = optionsMap.get(event.target.value);
        setTimeLeft(seconds);
        setTotalDuration(seconds);
    }

    function renderOptions() {
        return Array.from(optionsMap.entries()).map(([key, value]) => {
            return (
                <option key={key} value={key}>{key}</option>
            );
        })
    }

    let enabled = (!isRunning && !isPaused);

    return (
        <div class="container-fluid">
            <div class="row align-items-center" style={{"height": "50px"}}>
                <div class="col smallFontSize fontFamilyRegular fontColorDark">
                    Total Duration:
                </div>
                <select class="smallFontSize col-auto text-end me-2" value={selectedOption} onChange={handleChange} disabled={!enabled}>
                    {renderOptions()}
                </select>
            </div>
        </div>
    );
}

function SongChangeComponent({ songLength, setSongLength, isRunning, isPaused }) {

    const [selectedOption, setSelectedOption] = useState("1 minute");

    const optionsMap = new Map([
        ["5 seconds", 5],
        ["30 seconds", 30],
        ["1 minute", 60],
        ["90 seconds", 90],
        ["2 minutes", 120]
    ]);

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        setSongLength(optionsMap.get(event.target.value));
    };

    function renderOptions() {
        return Array.from(optionsMap.entries()).map(([key, value]) => {
            return (
                <option key={key} value={key}>{key}</option>
            );
        })
    }

    let enabled = (!isRunning && !isPaused);

    return (
        <div class="container-fluid">
            <div class="row align-items-center" style={{"height": "50px"}}>
                <div class="col smallFontSize fontFamilyRegular fontColorDark">
                    Timer Interval:
                </div>
                <select class="smallFontSize col-auto text-end me-2" value={selectedOption} onChange={handleChange} disabled={!enabled}>
                    {renderOptions()}
                </select>
            </div>
        </div>
    );
}

function StopButton({ onClick, isRunning }) {

    let enabled = (isRunning);

    return (

        <div class="container-fluid">
            <div class="row">
                <div class="col-1"/>
                <div class="col-10">
                    <div key="stopButton">
                        <button class="buttonClass fontFamilyRegular smallFontSize" onClick={onClick} disabled={!enabled}>
                            Stop
                        </button>
                    </div>
                </div>  
                    <div class="col-1"/>
            </div>
        </div>
    );
}

function ResumeButton({ onClick, isPaused }) {

    let enabled = (isPaused);

    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-1"/>
                <div class="col-10">
                    <div key="resumeButton">
                        <button class="buttonClass fontFamilyRegular smallFontSize" onClick={onClick} disabled={!enabled}>
                            Resume
                        </button>
                    </div>
                </div>  
                <div class="col-1"/>
            </div>
        </div>
    );
}

function StartButton({ onClick, isRunning, isPaused }) {

    let enabled = (!isRunning && !isPaused);

    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-1"/>
                <div class="col-10">
                    <div key="startButton">
                        <button class="buttonClass fontFamilyRegular smallFontSize" onClick={onClick} disabled={!enabled}>
                            Start
                        </button>
                    </div>
                </div>  
                <div class="col-1"/>
            </div>
        </div>
    );
}

function PauseButton({ onClick, isRunning, isPaused }) {

    let enabled = (isRunning && !isPaused);

    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-1"/>
                <div class="col-10">
                    <div key="pauseButton">
                        <button class="buttonClass fontFamilyRegular smallFontSize" onClick={onClick} disabled={!enabled}>
                            Pause
                        </button>
                    </div>
                </div>  
                <div class="col-1"/>
            </div>
        </div>
    );
}