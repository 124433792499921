import './App.css';
import './index.css';

import LoginComponent from './components/LoginComponent.js';
import ErrorComponent from './components/Error.js';
import AuthorizeCallbackComponent from './components/AuthorizeComponent.js';
import UserComponent from './components/UserComponent.js';

import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

export default function MyApp() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <UserComponent />,
      // errorElement: <ErrorComponent />,
    },
    {
      path: "authorizeCallback",
      element: <AuthorizeCallbackComponent />,
    },
  ]);
  return (
    // <React.StrictMode>
    <RouterProvider router={router} />
    // </React.StrictMode>
  );
}